<!--
Copyright 2017 ODK Central Developers
See the NOTICE file at the top-level directory of this distribution and at
https://github.com/getodk/central-frontend/blob/master/NOTICE.

This file is part of ODK Central. It is subject to the license terms in
the LICENSE file found in the top-level directory of this distribution and at
https://www.apache.org/licenses/LICENSE-2.0. No part of ODK Central,
including this file, may be copied, modified, propagated, or distributed
except according to the terms contained in the LICENSE file.
-->
<template>
  <div v-show="state" class="loading">{{ $t('text') }}</div>
</template>

<script>
export default {
  name: 'Loading',
  props: {
    state: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.loading {
  color: #999;
  margin-bottom: 10px;
  text-align: center;
}
</style>

<i18n lang="json5">
{
  "en": {
    "text": "Loading…"
  }
}
</i18n>

<!-- Autogenerated by destructure.js -->
<i18n>
{
  "cs": {
    "text": "Načítání ..."
  },
  "de": {
    "text": "Wird geladen..."
  },
  "es": {
    "text": "Cargando..."
  },
  "fr": {
    "text": "Chargement..."
  },
  "id": {
    "text": "Memuat..."
  },
  "it": {
    "text": "Caricando..."
  },
  "ja": {
    "text": "読み込み中..."
  }
}
</i18n>
